var app = {
	init: function() {
		// Initialize the logging module
		logging.init();

		// Initialize the audio player
		audio.init();

		this._minimumTheta = -3.5;
		this._maximumTheta = 3.5;
		this._thetaIncrement = 0.01;
		this._probabilityDensity = this.calculateProbabilityDensity();
		this._maxItemCount = undefined;
		this._itemDifficultyThreshold = 0.5;

		// Save URL parameters
		this._cn = undefined;
		this._schoolId = '';
		this._childId = undefined;
		this._grade = undefined;
		this._firstName = undefined;
		this._lastName = undefined;
		this._isDebugging = false;
		this._normalizeGrade = (grade) => {
			const normalizedGrade = Math.max(0, Math.min(Math.round(grade), 3));
			if (isNaN(normalizedGrade)) {
				console.error(`inside _normalizeGrade: grade is "${grade}", normalizedGrade is "${normalizedGrade}"`);
				window.location.href = 'error.html';
			} else {
				return normalizedGrade;
			}
		};
		const pageURL = window.location.search.substring(1);
		const URLVariables = pageURL.split('&');
		for (let i = 0; i < URLVariables.length; i++) {
			const [variable, value] = URLVariables[i].split('=');

			if ((typeof variable !== "undefined") && (variable !== "")) {
				switch (variable.toLowerCase()) {
					case "childid":
						this._childId = parseInt(value);
						break;
					case "cn":
						this._cn = value;
						break;
					case "firstname":
						this._firstName = decodeURIComponent(value);
						break;
					case "grade":
						this._grade = this._normalizeGrade(value);
						break;
					case "forcegrade":
						this._grade = Math.round(value);
						break;
					case "lastname":
						this._lastName = decodeURIComponent(value);
						break;
					case "schoolid":
						this._schoolId = parseInt(value);
						break;
					case "debug":
						this._isDebugging = parseInt(value) === 1;
						break;
					case "maxitems":
						this._maxItemCount = parseInt(value);
						break;
					default:
						console.log(`  Ignoring unrecognized URL parameter "${URLVariables[i]}"`);
				}
			}
		}

		// If the student's grade didn't get defined above, redirect to the error page
		if (typeof this._grade === "undefined") {
			console.error('Grade not found');
			window.location.href = 'error.html';
		}

		// Set the welcome string using the child's first name (if available)
		if (typeof this._firstName !== "undefined") {
			$("div#splashScreenWelcomeText").html(`¡Bienvenido, ${this._firstName}!`);
			$("div#studentName").html(`${this._firstName} ${this._lastName}`);
		}

		// Get the root node of the DOM for the test area & keep it handy
		this._testAreaDiv = $("div#testArea");

		// Initialize the running test parameters
		this._testDeliveryId = undefined;
		this._itemType = undefined;
		this._itemTheta = undefined;

		// Student ability estimate, calculated using theta
		this._abilityEstimateTheta = this.convertGradeEquivalentToTheta(this._grade);
		this._standardError = undefined;
		this._standardErrorThreshold = 0.25;

		this._numItemsCompleted = 0;
		this._numItemsCompletedByType = {};
		this._completedItems = [];
		this._lastAnswerCorrect = false;
		this._numIncorrectInARow = 0;
		this._itemStartTime_ms = 0;
		this._now = new Date();

		this.curPageName = "Main";
		this._currentItem = null;
		this._currentItemDef = undefined;
		this._currentItemNumTries = 0;

		// The function used to get the next item (based on test type in test definition)
		this._getNextItem = this.getNextItemAdaptive;

		// No test/item definitions yet
		this._testDef = null;
		this._itemDefs = null;
		this._closingItems = [];
		this._closingItemIndex = null;
		this._experimentalItemsPerSubtest = 3;

		this._itemTypesWithExperimentalItems = [
			'letters2Word',
			'wordRecognition'
		];

		this._itemTypes = [
			'letterIdentification',
			'soundIdentification',
			'syllableIdentification',
			'letters2Word',
			'wordRecognition',
			'words2Sentence',
			'finale'
		];

		// Initialize the number of items completed by type
		var that = this;
		$.each(this._itemTypes, function(itemTypeIdx, itemType) {
			that._numItemsCompletedByType[itemType] = 0;
		});

		// The table of supported item classes
		this._itemClasses = [
			"active",
			"training",
			"experimental"
		];

		// Bound callbacks for global controls
		this._onAssessmentsCallback = this.onAssessments.bind(this);
		this._onContinueCallback = this.onContinue.bind(this);
		this._onNextCallback = this.onNext.bind(this);
		this._onReplayCallback = this.onReplay.bind(this);
		this._onStartNewSessionCallback = this.onStartNewSession.bind(this);

		// The CSS font family specification for choice text (for measurement utilities)
		this._choiceFontFamily = "ProximaNovaSoft-Bold,Arial,sans-serif";

		/******************************/
		/* Set up the global controls */
		/******************************/

		// Initialize the "A2I Assessements" button
		$("#A2iAssessmentsButton").button({
			disabled: false,
			label: "A2i Assessments",
			text: true
		});
		$("#A2iAssessmentsButton").click(this._onAssessmentsCallback);

		// Initialize the "Listen Again" button
		$(".replayButton").button({
			disabled: false,
			label: "Escuchar otra vez",
			text: true
		});
		$(".replayButton").click(this._onReplayCallback);

		// Initialize the volume slider
		$("#volumeSlider").slider({
			disabled: false,
			max: 100,
			min: 0,
			orientation: "horizontal",
			range: false,
			step: 1,
			value: Math.round(audio.getVolume() * 100),
			change: function(event, uiObj) {
				var value = uiObj.value;
				audio.setVolume(value / 100.0);
			},
			slide: function(event, uiObj) {
				var value = uiObj.value;
				audio.setVolume(value / 100.0);
			}
		});

		var that = this;

		logging.getTestDelivery(this.curPageName, "L2M", this._schoolId, this._childId, this._grade, function(data, textStatus, jqXHR) {
			if (
				(typeof data !== "undefined") &&
				(typeof data.responseID !== "undefined") &&
				(data.responseID === "testDeliveryResult") &&
				(typeof data.resultCode !== "undefined") &&
				(data.resultCode === 0) &&
				(typeof data.payload !== "undefined")
			) {
				that._testDeliveryId = data.payload.testDeliveryID;
				logging._testDeliveryID = that._testDeliveryId;
			}
		});

		// Initialize the "Start Session" button area
		$("div#splashScreenSessionText").text("Start New Session");
		$("#startButton").click(that._onStartNewSessionCallback);
	
		// Initialize the progress bar
		$("#progressBar").progressbar({
			disabled: false,
			max: 100,
			value: 0
		});

		// Initialize the "Next" button
		$(".nextButton").button({
			disabled: false,
			icons: {
				secondary: "ui-icon-arrowthick"
			},
			label: "Siguiente",
			text: true
		});
		$(".nextButton").click(this._onNextCallback);

		// Initialize the "Continue" button
		$(".continueButton").button({
			disabled: false,
			label: "¡Fin!",
			text: true
		});
		$(".continueButton").click(this._onContinueCallback);

		// Initialize visibilities for screens & global controls
		$("div#splashScreen").removeClass("hidden");
		$("div#contentArea").addClass("hidden");
		// Wait 2s then hide the loading screen.  The 2s wait is just to avoid flashing the loading
		// screen too quickly to read it, which could be confusing
		setTimeout(function() {
			$("div#loading-screen").addClass("hidden");
		}, 2000);
		this.hideNextButton();
		// DEBUG: Show the next button all the time
		// this.showNextButton();
		this.hideContinueFrame(true);

		// Because this assessment was written to be a fixed size on the screen, with all
		// elements absolutely positioned within, there's no good way to adapt it to
		// different screen sizes.  It's the least responsive layout possible.  As a hack,
		// I've written code that will automatically apply a css scale transform to
		// the entire test to make it fit the screen.  It turns out jQuery UI is not
		// compatible out of the box with css3 transforms, so I had to get creative.

		// Sorry, future Nick.

		// Set the scale of the test based on the css scale transform
		app.setTestScale();

		// Recalculate test scale any time the window is resized
		$(window).resize(app.setTestScale);

		// Display test stats in debug mode
		if (this._isDebugging) {
			$('#debug').removeClass('hidden');
			app.updateDebugInformation();
		}
	},

	testScale: 1,

	setTestScale: function() {
		var matches = $('#toplevel').css('transform').match(/matrix\(([\d.]+),/);

		if (matches && matches.length) {
			app.testScale = parseFloat(matches[1]);
		} else {
			return 1;
		}
	},

	updateDebugInformation: function() {
		$('#debug td.item-number').html(this._numItemsCompleted + 1);
		$('#debug td.item-label').html(this._currentItemDef ? this._currentItemDef.itemLabel : '');
		$('#debug td.item-class').html(this._currentItemDef ? this._currentItemDef.itemClass : '');
		$('#debug td.ability-estimate').html(this._abilityEstimateTheta.toFixed(2));
		$('#debug td.item-difficulty').html(this._currentItemDef ? this._currentItemDef.difficulty : '');
		$('#debug td.consecutive-misses').html(this._numIncorrectInARow);
		
		if (this._testDef) {
			const maxItemsInSubtest = this._testDef.parametersByItemType[this._itemType].maxItemCount;
			$('#debug td.max-items-in-subtest').html(maxItemsInSubtest);
		}

		if (this._numItemsCompletedByType) {
			const answeredItemsInSubtest = this._numItemsCompletedByType[this._itemType];
			$('#debug td.answered-items-in-subtest').html(answeredItemsInSubtest);
		}

		let standardError = '';

		if (typeof this._standardError !== 'undefined') {
			standardError = this._standardError.toFixed(2);
		}

		$('#debug td.standard-error').html(standardError);

		let itemTheta = '';

		if (typeof this._itemTheta !== 'undefined') {
			itemTheta = this._itemTheta.toFixed(2);
		}

		$('#debug td.target-difficulty').html(itemTheta);
	},

	/****************************************************************/
	/* Handlers for global controls (button clicks & volume slider) */
	/****************************************************************/

	goToAssessmentsPage: function() {
		// Navigate to the assessments screen

		let assessmentsPageUrl = window.l2mConfig.redirectUrlAfterCompletion;

		if (window.l2mConfig.shouldAppendSchoolIdToRedirect) {
			assessmentsPageUrl += this._schoolId;
		}

		if (window.l2mConfig.shouldAppendStudentIdToRedirect) {
			assessmentsPageUrl += this._childId;
		}

		window.location = assessmentsPageUrl;
	},

	onAssessments: function(evt) {
		// Log the button press
		logging.logButtonPressed(this.curPageName, "Assessments");

		// Return to the assessments screen
		this.goToAssessmentsPage();
	},

	onContinue: function(evt) {
		console.log("app.onContinue()");

		// Log the button press
		logging.logButtonPressed(this.curPageName, "Continue");

		// Return to the assessments screen
		this.goToAssessmentsPage();
	},

	onNext: function(evt) {
		// Log the button press
		logging.logButtonPressed(this.curPageName, "Next");

		// Lock-in the correctness of the answer (pull from current item)
		this._lastAnswerCorrect = this._currentItem.answerCorrect;

		if (this._currentItemDef.itemClass === 'active') {
			if (this._lastAnswerCorrect) {
				this._numIncorrectInARow = 0;
			} else {
				this._numIncorrectInARow += 1;
			}
		}

		// Set up & play any feedback required by the item
		this.configureFeedback(this._lastAnswerCorrect);
		audio.startSoundPlayback(this.onFeedbackFinished.bind(this));
	},

	onFeedbackFinished: function() {
		// Complete the current item
		this.completeItem();

		// Get the next item definition
		this._currentItemDef = this._getNextItem();

		if (this._currentItemDef) {
			console.log(`  found item ${this._currentItemDef.itemType}, ${this._currentItemDef.itemLabel}, ${this._currentItemDef.itemId}`);

			// Remove any DOM structure from the test area for the existing item
			this._testAreaDiv.empty();

			// Set the item definition label as the page name (for logging purposes)
			this.curPageName = this._currentItemDef.itemLabel;
			this.showItem();

			if (this._currentItemDef.itemType === "finale") {
				this.completeTest();
			}
		}

		if (this._isDebugging) {
			this.updateDebugInformation();
		}
	},

	onReplay: function(evt) {
		logging.logButtonPressed(this.curPageName, "Replay");

		audio.replayRequested();
	},

	onStartNewSession: function(evt) {
		logging.logButtonPressed(this.curPageName, "StartNewSession");

		// Load the item definitions (from static factory)
		this._itemDefs = itemsByType;

		// Initialize test definitions (from static factory)
		var testDefs = l2m_testDefFactory();

		// Log the start of the test
		logging.logTestStarted(this.curPageName);

		// Start the session
		this.startSession(testDefs);

		// Log debug info to the screen
		this.updateDebugInformation();
	},

	startSession: function(testDefs) {
		if (this._itemDefs && testDefs) {
			// Get the grade-based test definition
			if ((typeof this._grade !== "undefined") && (this._grade in testDefs.grades)) {
				this._testDef = testDefs.grades[this._grade];
			} else {
				this._testDef = testDefs.grades["default"];
			}

			this._itemType = this._testDef.initialItemType;
			this._itemTheta = this._testDef.initialItemTheta;
			
			if (typeof this._maxItemCount === 'undefined') {
				this._maxItemCount = this._testDef.maxItemCount;
			}

			// Construct the test content and make it visible
			this._currentItemDef = this._getNextItem();
			console.log(`  found item ${this._currentItemDef.itemType}, ${this._currentItemDef.itemLabel}, ${this._currentItemDef.itemId}`);

			// Set the item definition label as the page name (for logging purposes)
			this.curPageName = this._currentItemDef.itemLabel;

			// Display the new item
			this.showItem();
			$("div#contentArea").removeClass("hidden");

			// Hide the splash screen
			$("div#splashScreen").addClass("hidden");

			this.updateDebugInformation();
		}
	},

	/***********************************/
	/* Global control state management */
	/***********************************/

	hideContinueFrame: function(isHidden) {
		if (isHidden) {
			$("div#continueFrame").addClass("hidden");
		} else {
			$("div#continueFrame").removeClass("hidden");
		}
	},

	showNextButton: function() {
		$("button.nextButton").removeClass("hidden");
	},

	hideNextButton: function() {
		$("button.nextButton").addClass("hidden");
	},

	showHideNextButton: function(isShown) {
		if (isShown) {
			this.showNextButton();
		} else {
			this.hideNextButton();
		}
	},

	enableNextButton: function(isEnabled) {
		//$("button.nextButton").button("option", "disabled", !isEnabled);
		this.showHideNextButton(isEnabled);
	},

	showReplayButton: function() {
		$("button.replayButton").removeClass("disabled");
	},

	hideReplayButton: function() {
		$("button.replayButton").addClass("disabled");
	},

	showHideReplayButton: function(isShown) {
		if (isShown) {
			this.showReplayButton();
		} else {
			this.hideReplayButton();
		}
	},

	enableReplayButton: function(isEnabled) {
		$("button.replayButton").button("option", "disabled", !isEnabled);
	},

	updateProgressBar: function(value) {
		let newValue;
		let maxItemCount = this._maxItemCount;
		
		// Some students will see experimental items; add the max possible # of experimental items
		// to the max item count to calculate progress bar length
		maxItemCount += this._experimentalItemsPerSubtest * this._itemTypesWithExperimentalItems.length;

		if (typeof value !== 'undefined') {
			newValue = value;
		} else {
			newValue = (this._numItemsCompleted / (maxItemCount)) * 100;
		}

		// Update the UI
		$("div#progressBar").progressbar("value", newValue);
	},

	/*******************/
	/* Item management */
	/*******************/

	getActiveAnswers: function() {
		return this._completedItems.filter((item) => item.itemDef.itemClass === 'active');
	},

	calculateProbabilityOfCorrectAnswer: function(discrimination, difficulty, studentAbility) {
		// NV 9/22/23: the researchers haven't actually determined discrimination values
		// for the Spanish items.  We're implementing a two-factor model here that expects
		// discrimination to future-proof the project, but just setting it to a fixed value of
		// 1 so that everything works as expected.  This should effectively break down to
		// a 1-parameter model.
		discrimination = 1;

		return(Math.exp(discrimination * (studentAbility - difficulty)) / (1 + (Math.exp(discrimination * (studentAbility - difficulty)))));
	},

	calculateProbabilityDensity: function() {
		// In IRT terms, the output here is actually called the "probability density function" but that would be
		// a confusing overloading of the term "function" in this context.  It's actually just a
		let probabilityDensity = {};

		for (let theta = this._minimumTheta; theta <= this._maximumTheta; theta += this._thetaIncrement) {
			probabilityDensity[theta] = ((1 / Math.sqrt(2 * Math.PI)) * Math.exp(-0.5 * (Math.pow(theta, 2))));
		}

		return probabilityDensity;
	},

	updateAbilityEstimate: function() {
		const activeAnswers = this.getActiveAnswers();

		let highestBayesianAdjustedLikelihood = 0,
			mostLikelyStudentAbility = this._minimumTheta;

		for (let theta = this._minimumTheta; theta <= this._maximumTheta; theta += this._thetaIncrement) {
			let naturalLogLLValues = [];

			activeAnswers.forEach((answer) => {
				const difficulty = answer.itemDef.difficulty;
				const discrimination = answer.itemDef.discrimination;
				const probabilityOfCorrectAnswer = this.calculateProbabilityOfCorrectAnswer(discrimination, difficulty, theta);
				const isCorrectAsInteger = answer.isCorrect ? 1 : 0;

				const naturalLogLL = Math.log(Math.pow(probabilityOfCorrectAnswer, isCorrectAsInteger) * (Math.pow(1 - probabilityOfCorrectAnswer, 1 - isCorrectAsInteger)));

				naturalLogLLValues.push(naturalLogLL);
			});

			const likelihood = Math.exp(naturalLogLLValues.reduce((a, b) => a + b));
			const bayesianAdjustedLikelihood = this._probabilityDensity[theta] * likelihood;

			if (bayesianAdjustedLikelihood >= highestBayesianAdjustedLikelihood) {
				highestBayesianAdjustedLikelihood = bayesianAdjustedLikelihood;
				mostLikelyStudentAbility = theta;
			}
		}

		this._abilityEstimateTheta = mostLikelyStudentAbility;

		// Calculate standard error

		// Now that we have the most likely theta value for the student's ability, we can recalculate "true" probabilities
		// for each item using this theta value, and then use those values to calculate standard error

		let termsToSum = [];

		activeAnswers.forEach((answer) => {
			const difficulty = answer.itemDef.difficulty;
			
			// NV 9/22/23: The researchers haven't determined discrimination values yet - see
			// comment in `calculateProbabilityOfCorrectAnswer` function

			// const discrimination = answer.itemDef.discrimination;
			const discrimination = 1;
			const trueProbability = this.calculateProbabilityOfCorrectAnswer(discrimination, difficulty, mostLikelyStudentAbility);

			termsToSum.push(Math.pow(discrimination, 2) * trueProbability * (1 - trueProbability));
		});

		const summedTerms = termsToSum.reduce((a, b) => a + b);
		const standardError = Math.sqrt(1 / summedTerms);
		this._standardError = standardError;
	},

	hasStudentSeenItemsOfType: function(itemType) {
		const itemsOfType = this._completedItems.filter((item) => {
			return item.itemDef.itemType === itemType;
		});

		return itemsOfType.length > 0;
	},

	shuffleArray: function(inputArray) {
		return inputArray.sort(() => 0.5 - Math.random());
	},

	getExperimentalItemsOfType: function(itemType) {
		return this._itemDefs[itemType].items.filter((item) => {
			return item.itemClass === 'experimental';
		});
	},

	getRandomExperimentalItemsOfType: function(itemType, itemCount) {
		const experimentalItemsOfType = this.getExperimentalItemsOfType(itemType);
		const shuffledItems = this.shuffleArray(experimentalItemsOfType);
		const randomItems = shuffledItems.slice(0, itemCount);
		return randomItems;
	},

	setClosingItems: function() {
		const itemCountToShow = this._experimentalItemsPerSubtest;

		this._itemTypesWithExperimentalItems.forEach((itemType) => {
			const hasSeenItemType = this.hasStudentSeenItemsOfType(itemType);

			if (hasSeenItemType) {
				const experimentalItems = this.getRandomExperimentalItemsOfType(itemType, itemCountToShow);
				this._closingItems = this._closingItems.concat(experimentalItems);
			}
		});

		this._closingItems.push(this._itemDefs.finale.items[0]);
		this._closingItemIndex = 0;
	},

	getNextItemAdaptive: function() {
		var itemDef = null;

		// We've completed the required number of active items, or the error
		// in our GE estimate is small enough -- finish the assessment

		if (this._closingItemIndex !== null) {
			this._closingItemIndex++;
			console.log(`this._closingItemIndex is not null, moving to closingItemIndex ${this._closingItemIndex}`);
			return this._closingItems[this._closingItemIndex];
		}

		if (
			(this._numItemsCompleted >= this._maxItemCount) ||
			((typeof this._standardError !== 'undefined') && (this._standardError < this._standardErrorThreshold))
		) {
			this.setClosingItems();
			console.log(`Required number of active items completed, moving to closing items`);
			return this._closingItems[this._closingItemIndex];
		}

		// Are there any training items for the current item type?
		if (
			(typeof this._itemDefs[this._itemType].trainingItemIds !== "undefined") &&
			(this._itemDefs[this._itemType].trainingItemIds !== null) &&
			(this._itemDefs[this._itemType].trainingItemIds.length > 0)
		) {
			// Yes -- get the first available training item
			console.log(`Finding training item from _itemDefs by type = ${this._itemType}, id = ${this._itemDefs[this._itemType].trainingItemIds[0]}`);
			itemDef = this._itemDefs.findItemDefByTypeAndId(this._itemType,
				this._itemDefs[this._itemType].trainingItemIds[0]);
			return itemDef;
		}

		// Have we reached the limit of items we can ask of the current item type?
		var triggered = false;
		var directionUp;

		const subtestCompletedItemCount = this._numItemsCompletedByType[this._itemType];
		const subtestMaxItemCount = this._testDef.parametersByItemType[this._itemType].maxItemCount;

		if (subtestCompletedItemCount >= subtestMaxItemCount) {
			// Yes -- force a switch "upwards" to the next item type
			console.log(`Advancing to next subtest - student has answered ${subtestCompletedItemCount} out of a maximum of ${subtestMaxItemCount} items in this subtest`);
			directionUp = true;
			triggered = true;
		} else {
			// No -- set the transition direction based on whether the last answer was correct
			directionUp = this._lastAnswerCorrect;
		}

		// If we've asked the minimum # of items of this type...
		var minItemCount = 2;
		if (this._testDef.parametersByItemType[this._itemType].minItemCount) {
			minItemCount = this._testDef.parametersByItemType[this._itemType].minItemCount;
		}
		if (this._numItemsCompletedByType[this._itemType] >= minItemCount) {
			// ... check the item type transition rules to see if we need to switch item types and/or update the GE
			var thresholdName = this._itemType + (directionUp ? "Ascent" : "Descent");
			if (thresholdName in this._testDef.itemTypeThresholds) {
				var thresholdRule = this._testDef.itemTypeThresholds[thresholdName];

				if (!triggered) {
					switch (thresholdRule.condition) {
						case "lessThan":
							if (
								(thresholdRule.variable in this) &&
								(this.roundToTenths(this[thresholdRule.variable]) < this.roundToTenths(thresholdRule.value))
							) {
								triggered = true;
							}
							break;

						case "lessThanOrEqualTo":
							if (
								(thresholdRule.variable in this) &&
								(this.roundToTenths(this[thresholdRule.variable]) <= this.roundToTenths(thresholdRule.value))
							) {
								triggered = true;
							}
							break;

						case "greaterThan":
							if (
								(thresholdRule.variable in this) &&
								(this.roundToTenths(this[thresholdRule.variable]) > this.roundToTenths(thresholdRule.value))
							) {
								triggered = true;
							}
							break;

						case "greaterThanOrEqualTo":
							if (
								(thresholdRule.variable in this) &&
								(this.roundToTenths(this[thresholdRule.variable]) >= this.roundToTenths(thresholdRule.value))
							) {
								triggered = true;
							}
							break;

						case "equalTo":
							if ((thresholdRule.variable in this) &&
								(Math.abs(this.roundToTenths(this[thresholdRule.variable]) - this.roundToTenths(thresholdRule.value)) < 0.001)
							) {
								triggered = true;
							}
							break;

						default:
							console.log(`  Unsupported threshold rule condition "${thresholdRule.condition}" found -- ignoring rule`);
							break;
					}
				}

				if (triggered) {
					var prevItemType = this._itemType;

					if (
						prevItemType === "words2Sentence" &&
						directionUp == false
					) {
						const activeAnswers = this.getActiveAnswers();
						const letters2WordAnswers = activeAnswers.filter((item) => item.itemDef.itemType === 'letters2Word');
						const maxLetters2WordAnswers = this._testDef.parametersByItemType.letters2Word.maxItemCount;

						console.log('***** 1');
						console.log(activeAnswers, letters2WordAnswers, maxLetters2WordAnswers);
						
						if (letters2WordAnswers && letters2WordAnswers.length >= maxLetters2WordAnswers) {
							console.log("Student was bounced back from words2Sentence but already reached max # of items in letters2Word; ending test early");
							this.setClosingItems();
							return this._closingItems[this._closingItemIndex];
						}
					}		

					// The threshold rule was triggered -- apply the results
					console.log(`  threshold rule "${thresholdName}" triggered (pre): _itemType = ${this._itemType}, _itemTheta = ${this._itemTheta}`);
					for (var i = 0; i < thresholdRule.results.length; i++) {
						this[thresholdRule.results[i].variable] = thresholdRule.results[i].value;
					}

					// Are we supposed to end the test now?
					if (this._itemType === "finale") {
						this.setClosingItems();
						console.log(`Triggered threshold rule to end test now`);
						return this._closingItems[this._closingItemIndex];
					}

					// Is the new item type legal (e.g. has valid items & within the ask limit)?
					while (
						(typeof this._itemType !== "undefined") &&
						((this._itemDefs[this._itemType].items.length == 0) ||
						(this._testDef.parametersByItemType[this._itemType].maxItemCount <= this._numItemsCompletedByType[this._itemType]))
					) {
						if (this._itemDefs[this._itemType].items.length == 0) {
							console.log(`  Item type "${this._itemType}" exhausted -- skipping`);
						}
						if (this._testDef.parametersByItemType[this._itemType].maxItemCount <= this._numItemsCompletedByType[this._itemType]) {
							console.log(`  Item type "${this._itemType}" ask limit reached -- skipping`);
						}

						if (directionUp) {
							this._itemType = this.getNextItemType(this._itemType);
						} else {
							this._itemType = this.getPrevItemType(this._itemType);
						}
						console.log(`  New item type: "${this._itemType}"`);
					}

					if (typeof this._itemType === "undefined") {
						console.log(`Threshold rule "${thresholdName}" triggered, but could not get new item type -- terminating test`);
						this._itemType = "finale";
					}

					console.log(`  threshold rule "${thresholdName}" triggered (post): _itemType = ${this._itemType}, _itemTheta = ${this._itemTheta}`);

					if (prevItemType != this._itemType) {
						// We're switching item types -- reset our count of # of incorrect responses in a row for the current item type
						this._numIncorrectInARow = 0;
					}

					// Call ourselves again to get the next available item
					itemDef = this._getNextItem();
					return itemDef;
				}
			}
		}

		console.log(`Finding items near target difficulty of ${this._itemTheta}`);
		const items = this._itemDefs[this._itemType].items.filter((item) => {
			return item.itemClass === 'active';
		});

		let candidateItems = [];

		// Pass 2: Retrieve all of the closest items as candidates
		console.log(`Last answer was ${this._lastAnswerCorrect ? '' : 'in'}correct; looking for ${this._lastAnswerCorrect ? 'harder' : 'easier'} items`);

		items.forEach((item) => {
			const distanceFromTarget = Math.abs(item.difficulty - this._itemTheta);
			const isWithinThreshold = distanceFromTarget <= this._itemDifficultyThreshold;
			const isEasier = item.difficulty <= this._itemTheta;
			const isHarder = item.difficulty >= this._itemTheta;
			
			let isItemAppropriate;

			if (this._lastAnswerCorrect) {
				isItemAppropriate = isWithinThreshold && isHarder;
			} else {
				isItemAppropriate = isWithinThreshold && isEasier;
			}

			if (isItemAppropriate) {
				candidateItems.push(item);
			}
		});

		console.log(`${candidateItems.length} items found`);

		// If we didn't find any items in the preferred direction, find any within threshold
		if (candidateItems.length === 0) {
			console.log('Looking for any items within difficulty threshold');
			
			items.forEach((item) => {	
				const distanceFromTarget = Math.abs(item.difficulty - this._itemTheta);
				const isWithinThreshold = distanceFromTarget <= this._itemDifficultyThreshold;

				if (isWithinThreshold) {
					candidateItems.push(item);
				}
			});
		}

		// Finally, randomly select a candidate item definition
		if (candidateItems.length === 0) {
			// If no candidate items were found, grab the 3 closest and pick one randomly
			const sortedItems = items.sort((itemA, itemB) => {
				const distanceA = Math.abs(itemA.difficulty - this._itemTheta);
				const distanceB = Math.abs(itemB.difficulty - this._itemTheta);
				return distanceA - distanceB;
			});
			candidateItems = sortedItems.slice(0, 3);
			console.log('No items found within threshold.  Selecting randomly from 3 closest items');
			itemDef = candidateItems[Math.floor(candidateItems.length * Math.random())];
		} else if (candidateItems.length > 1) {
			console.log(`Selecting randomly from ${candidateItems.length} items within threshold`);
			itemDef = candidateItems[Math.floor(candidateItems.length * Math.random())];
		} else {
			console.log(`Only 1 item found within threshold, selecting it by default`);
			itemDef = candidateItems[0];
		}

		console.log(`Found next item: ${itemDef.itemLabel}, difficulty: ${itemDef.difficulty}`);
		return itemDef;
	},

	findItemById: function(itemId) {
		const items = itemsByType[this._itemType];
		const filteredItems = items.filter((item) => item.itemId === itemId);
	
		if (filteredItems.length > 0) {
			return filteredItems[0];
		} else {
			throw `Attempted to access question that could not be found.  Type: \`${this._itemType}\`, Question set index: \`${this._questionSetIndex}\`, item ID: \`${itemId}\``;
		}
	},

	showItem: function() {
		// Hide the "Next" button until the item re-displays it (e.g. after they've answered)
		this.hideNextButton();
		// DEBUG: Show the next button all the time
		//        this.showNextButton();

		if (this._currentItemDef) {
			switch (this._currentItemDef.itemType) {
				case "letterIdentification":
				case "soundIdentification":
				case "syllableIdentification":
					this._currentItem = new Letters2Meaning_LetterSelectItem(this._testAreaDiv, this._currentItemDef);
					break;

				case "letters2Word":
					this._currentItem = new Letters2Meaning_Letters2WordItem(this._testAreaDiv, this._currentItemDef);
					break;

				case "wordRecognition":
				case "wordRecognitionDecodable":
				case "wordRecognitionNonDecodable":
					this._currentItem = new Letters2Meaning_WordSelectItem(this._testAreaDiv, this._currentItemDef);
					break;

				case "words2Sentence":
					this._currentItem = new Letters2Meaning_Words2SentenceItem(this._testAreaDiv, this._currentItemDef);
					break;

				default:
					console.log(`  Unknown item type "${this._currentItemDef.itemType}" ignored`);
					// Intentionally fall through to the finale

				case "finale":
					this._currentItem = new Letters2Meaning_Finale(this._testAreaDiv, this._currentItemDef);
					break;
			}

			// Keep track of the start time
			var now = new Date();
			this._itemStartTime_ms = now.getTime();

			// Increment the try counter
			this._currentItemNumTries++;

			// Configure prompt/response sounds w/ initial pacing (first try)
			this.configureSounds(false);
			audio.setHasResponded(false);
			audio.startSoundPlayback();
		}
	},

	getPrevItemType: function(currentItemType) {
		var prevItemType = undefined;

		var idx = $.inArray(currentItemType, this._itemTypes);
		if (idx > 0) {
			prevItemType = this._itemTypes[idx - 1];
		}

		return prevItemType;
	},

	getNextItemType: function(currentItemType) {
		var nextItemType = undefined;

		var idx = $.inArray(currentItemType, this._itemTypes);
		if ((idx != -1) && (idx < (this._itemTypes.length - 1))) {
			nextItemType = this._itemTypes[idx + 1];
		}

		return nextItemType;
	},

	completeItem: function() {
		if (this._currentItemDef.itemType !== "finale") {
			// Update the history
			this._completedItems.push({
				itemId: this._currentItemDef.itemId,
				itemDef: this._currentItemDef,
				isCorrect: this._lastAnswerCorrect
			});

			if (this._currentItemDef.itemClass !== 'training') {
				// Increment the number of items completed
				this._numItemsCompleted++;

				// Increment the per-type item count
				this._numItemsCompletedByType[this._itemType]++;

				this.updateProgressBar();
				this.updateAbilityEstimate();

				/* Set the target difficulty for the next item.  From Linacre, page 13:
				 *
				 * The first item a student sees is selected at random from those near
				 * 0.5 logits less than the initial estimated ability. This yields a
				 * putative 62% chance of success, thus providing the student, who may
				 * not be familiar with CAT, extra opportunity for success within the CAT
				 * framework. Randomizing item selection improves test security by
				 * preventing students from experiencing similar tests. Randomization
				 * also equalizes bank item use.
				 * After the student responds to the first item, a revised competency
				 * measure and standard error are estimated. Again, an item is chosen
				 * from those near 0.5 logits easier than the estimated competency. After
				 * the student responds, the competency measure is again revised and a
				 * further item selected and administered. This process continues.
				 * [...]
				 * Beginning with the sixth item, the difficulty of items is targeted
				 * directly at the test-taker competency, rather than 0.5 logits below.
				 * This optimal targeting theoretically provides the same measurement
				 * precision with 6% fewer test items.
				 */
				const activeAnswers = this.getActiveAnswers();

				if (activeAnswers.length >= 6) {
					this._itemTheta = this._abilityEstimateTheta;
				} else {
					const targetDifficultyTheta = this._abilityEstimateTheta - 0.5;
					this._itemTheta = targetDifficultyTheta;
				}
			}
		}

		// Was it a training item, answered incorrectly, with retries available?
		if (
			(this._currentItemDef.itemClass == "training") &&
			(this._lastAnswerCorrect == false) &&
			(this._itemDefs[this._itemType].numTrainingItemRetries > 0)
		) {
			// Yes -- don't purge the completed item, retry it instead
			// Decrement the available training item retries
			this._itemDefs[this._itemType].numTrainingItemRetries -= 1;
		} else {
			// Update our item definitions (this._itemDefs) to remove the completed item
			this._itemDefs.purgeItem(this._itemType, this._currentItemDef.itemId);

			// Reset the try counter
			this._currentItemNumTries = 0;
			const abilityEstimateGE = this.convertThetaToGradeEquivalent(this._abilityEstimateTheta);

			// Log the completion of the item
			var now = new Date();
			logging.logItemCompleted(
				this.curPageName,
				this._currentItemDef.itemId,
				this._currentItemDef.itemLabel,
				-1,
				this._currentItem.currentAnswer,
				this._lastAnswerCorrect,
				abilityEstimateGE,
				true,
				now.getTime() - this._itemStartTime_ms,
				this._standardError
			);
		}
	},

	completeTest: function() {
		this.updateProgressBar(100);

		// Log the completion of the test
		logging.logTestCompleted(this.curPageName);

		// Call the Web service to inject the final record into A2i
		this.sendTestCompletedToA2i();
	},

	getScoreConversionTable: function() {
		return [
			{ min: 351, max: 357, ge: -0.7 },
			{ min: 358, max: 364, ge: -0.5 },
			{ min: 365, max: 371, ge: -0.2 },
			{ min: 372, max: 376, ge: -0.1 },
			{ min: 377, max: 381, ge: 0.1 },
			{ min: 382, max: 385, ge: 0.2 },
			{ min: 386, max: 391, ge: 0.3 },
			{ min: 392, max: 395, ge: 0.4 },
			{ min: 396, max: 399, ge: 0.5 },
			{ min: 400, max: 403, ge: 0.6 },
			{ min: 404, max: 410, ge: 0.7 },
			{ min: 411, max: 414, ge: 0.8 },
			{ min: 415, max: 417, ge: 0.9 },
			{ min: 418, max: 424, ge: 1.00 },
			{ min: 425, max: 427, ge: 1.1 },
			{ min: 428, max: 434, ge: 1.2 },
			{ min: 435, max: 437, ge: 1.3 },
			{ min: 438, max: 440, ge: 1.4 },
			{ min: 441, max: 443, ge: 1.5 },
			{ min: 444, max: 446, ge: 1.6 },
			{ min: 447, max: 448, ge: 1.7 },
			{ min: 449, max: 451, ge: 1.8 },
			{ min: 452, max: 454, ge: 1.9 },
			{ min: 455, max: 457, ge: 2 },
			{ min: 458, max: 460, ge: 2.1 },
			{ min: 461, max: 463, ge: 2.2 },
			{ min: 464, max: 465, ge: 2.3 },
			{ min: 466, max: 468, ge: 2.4 },
			{ min: 469, max: 471, ge: 2.6 },
			{ min: 472, max: 473, ge: 2.8 },
			{ min: 474, max: 476, ge: 2.9 },
			{ min: 477, max: 478, ge: 3 },
			{ min: 479, max: 480, ge: 3.1 },
			{ min: 481, max: 483, ge: 3.3 },
			{ min: 484, max: 485, ge: 3.4 },
			{ min: 486, max: 487, ge: 3.6 },
			{ min: 488, max: 490, ge: 3.8 },
			{ min: 491, max: 492, ge: 4 },
			{ min: 493, max: 494, ge: 4.2 },
			{ min: 495, max: 496, ge: 4.4 },
			{ min: 497, max: 498, ge: 4.7 },
			{ min: 499, max: 500, ge: 4.9 },
			{ min: 501, max: 503, ge: 5.1 },
			{ min: 504, max: 505, ge: 5.4 },
			{ min: 506, max: 507, ge: 5.7 },
			{ min: 508, max: 509, ge: 6 },
			{ min: 510, max: 511, ge: 6.3 },
			{ min: 512, max: 513, ge: 6.7 }
		];
	},


	convertThetaToGradeEquivalent: function(theta) {
		const developmentalScaleScore = this.convertThetaToDevelopmentalScaleScore(theta);

		if (developmentalScaleScore <= 350) {
			return -1.0;
		} else if (developmentalScaleScore >= 514) {
			return 7.0;
		} else {
			const conversions = this.getScoreConversionTable();

			for (const range of conversions) {
				if (developmentalScaleScore >= range.min && developmentalScaleScore <= range.max) {
					return range.ge;
				}
			}
			
			throw new Error(`Could not find a grade equivalent for developmental scale score ${developmentalScaleScore}`);
		}
	},

	convertGradeEquivalentToTheta: function(gradeEquivalent) {
		/**
		 * Note that this is NOT actually the inverse of the `convertThetaToGradeEquivalent` function.
		 * The psychometrician provided a discontinuous lookup table for the conversion, making
		 * an inverse lookup indeterminate.  The equation used here is the old one from a prior calibration,
		 * and is used only to convert the student's grade into a reasonable starting point for the theta.
		 * There's no real need for a true inverse lookup, as the CAT algorithm will adjust the theta
		 * as the student answers questions.
		 */
		
		let theta;

		if (gradeEquivalent <= -0.9) {
			theta = this._minimumTheta;
		} else if (gradeEquivalent >= 5.9) {
			theta = this._maximumTheta;
		} else {
			const B = (-0.98);
			const A = 6.2;
			const xmid = -0.95;
			const scal = 1.02;
			const g = .95;

			theta = -1 * (xmid + scal * Math.log(Math.exp((Math.log((A - B) / (gradeEquivalent - B))) / g) - 1));
			theta = Math.max(Math.min(theta, this._maximumTheta), this._minimumTheta);
		}

		return theta;
	},

	convertThetaToDevelopmentalScaleScore: function(thetaScore) {
		// Parameters from the logistic model in the SAS code
		const a = 312.6;   // Lower asymptote
		const b = 1.6309;  // Slope
		const c = -0.4469; // Inflection point
		const d = 512.0;   // Upper asymptote

		// Logistic function to convert theta to W score
		const wScore = a + ((d - a) / (1 + Math.exp(-b * (thetaScore - c))));

		// Round wScore to the nearest integer
		return Math.round(wScore);
	},

	sendTestCompletedToA2i: function() {
		var serverURL = window.l2mConfig.serverUrl,
			abilityEstimateGE = this.convertThetaToGradeEquivalent(this._abilityEstimateTheta),
			developmentalScaleScore = this.convertThetaToDevelopmentalScaleScore(this._abilityEstimateTheta);

		// A GE of less then -0.4 will cause some of the A2i algorithms to go asymptotic, resulting in
		// hundreds of recommended minutes.  Ideally those algorithms would set their own limits, but for
		// now, we set a lower limit that L2M can produce.
		abilityEstimateGE = Math.max(abilityEstimateGE, -0.4);

		$.ajax({
			url: serverURL,
			async: false,
			type: 'GET',
			cache: false,
			data: {
				"test_date": moment().format('YYYY-MM-DD HH:mm:ss'),
				"test_delivery_id": this._testDeliveryId,
				"GE": this.roundToTenths(abilityEstimateGE),
				"raw_score": this._abilityEstimateTheta,
				"w_score": developmentalScaleScore
			},
			success: function(response) {
				// Do nothing
			},
			error: function(msg) {
				alert(`Test completion server communication failed: ${msg.status} : ${msg.statusText}`);
			}
		});
	},

	/*********/
	/* Sound */
	/*********/

	configureSounds: function(usePacing) {
		audio.cleanupSounds();
		if (typeof this._currentItemDef !== "undefined") {
			// if this is the first attempt at the first question, play the intro
			if (this._completedItems.length === 0 && this._currentItemNumTries === 1) {
				audio.addIntroSounds(['intro'], 250, 250);
			}
			
			// Configure the instruction & prompt audio we need to hear for this item
			if (this._currentItemDef.narration != null) {
				if (this._currentItemDef.narration.instructions != null) {
					const instructionsFile = `${this._currentItemDef.itemLabel}_instructions`;
					audio.addIntroSounds([instructionsFile],
						250, 250);
				}
				if (this._currentItemDef.narration.prompt != null) {
					const promptFile = `${this._currentItemDef.itemLabel}_prompt`;
					audio.addPromptSounds([promptFile],
						250, 250,
						true,
						true,
						true,
						!usePacing,
						undefined);
				}
	
				// Add time to wait (e.g. 30s) before automatically replaying the audio
				audio.addAutoReplayDelay();
			}
		}
	},
	
	configureFeedback: function() {
		audio.cleanupSounds();
		if (typeof this._currentItemDef !== "undefined") {
			// Configure the feedback audio we need to hear for this item
			if (this._currentItemDef.narration != null) {
				if (this._currentItemDef.narration.feedback != null) {
					for (var i = 0; i < this._currentItemDef.narration.feedback.length; i++) {
						var feedback = this._currentItemDef.narration.feedback[i];

						if (
							(feedback.tryCount == this._currentItemNumTries) &&
							(feedback.retriesRemaining == itemsByType[this._itemType].numTrainingItemRetries) &&
							(this._lastAnswerCorrect == feedback.correct)
						) {
							const item = this._currentItemDef;
							const filename = `${item.itemLabel}_feedback_t${feedback.tryCount}_${feedback.correct ? 'correct' : 'incorrect'}`;
	
							audio.addPromptSounds([filename],
								250, 250,
								false,
								false,
								false,
								false,
								undefined);
							break;
						}
					}
	
					// Make sure they can't click "Next" before feedback is done
					this.hideNextButton();
				}
			}
		}
	},	

	/*************/
	/* Utilities */
	/*************/

	getCSSPropertyAsNumber: function(cssPropStr) {
		return Number(cssPropStr.replace(/[^-\d.]/g, ""));
	},

	roundToTenths: function(num) {
		return Math.round((num + 0.001) * 10) / 10;
	},

	getChoiceCenterX: function(choiceObj) {
		return this.getCSSPropertyAsNumber(choiceObj.css("left")) +
			(this.getCSSPropertyAsNumber(choiceObj.css("width")) / 2);
	},

	buildAnswerArea: function(answerAreaId, parent) {
		// Construct the answer area with the given ID (for styling) and add it as a child to the given parent
		var answerArea = $("<div/>", {
			"class": "answerAreaTopBottomLines"
		});
		answerArea.append($("<div/>", {
			"class": "answerAreaMidline"
		}));

		if (answerAreaId) {
			answerArea.attr("id", answerAreaId);
		}

		if (parent) {
			answerArea.appendTo(parent);
		}

		return answerArea;
	},

	measureText: function(fontFamily, fontSize, fontWeight, textToMeasure) {
		// setting up html used for measuring text-metrics
		var container = document.getElementById("fontMetricsContainer");
		var parent;
		var image;
		if (!container) {
			// One-time HTML setup used for measuring text metrics
			image = document.createElement("img");
			image.id = "fontMetricsImage";
			image.width = "1px";
			image.height = "1px";
			image.src = "images/1x1.gif";

			parent = document.createElement("div");
			parent.id = "fontMetricsParent";
			parent.appendChild(document.createTextNode(textToMeasure));
			parent.appendChild(image);

			container = document.createElement("div");
			container.id = "fontMetricsContainer";
			container.style.position = "absolute";
			container.style.top = "-1000px";
			container.style.left = "-1000px";
			container.style.whiteSpace = "nowrap";
			container.appendChild(parent);

			$('body').append(container);
		} else {
			// Get the HTML elements we need
			parent = document.getElementById("fontMetricsParent");
			image = document.getElementById("fontMetricsImage");

			// Set the text string we want to measure
			parent.innerHTML = textToMeasure;

			// Reattach the image to the parent (orphaned by innerHTML)
			parent.appendChild(image);
		}

		// Set the default font family
		if (!fontFamily) {
			fontFamily = this._choiceFontFamily;
		}

		// Set the font info on the container so it's immune from the following changes
		container.style.fontFamily = fontFamily;
		container.style.fontSize = fontSize;
		container.style.fontWeight = fontWeight;

		// Getting css equivalent of ctx.measureText()
		image.style.display = "none";
		parent.style.display = "inline";
		var measureHeight = parent.offsetHeight;
		var measureWidth = parent.offsetWidth;

		// Making sure super-wide text stays in-bounds
		image.style.display = "inline";
		var forceWidth = measureWidth + image.offsetWidth;

		// Capturing the "top" and "bottom" baseline
		parent.style.cssText = `margin: 50px 0; display: block; width: ${forceWidth}px`;
		var TopCSS = image.offsetTop - 49;
		var HeightCSS = parent.offsetHeight;
		var BottomCSS = TopCSS - HeightCSS;

		// Capturing the "middle" baseline
		parent.style.cssText = `line-height: 0; display: block; width: ${forceWidth}px`;
		var MiddleCSS = image.offsetTop + 1;

		// Reset for next time
		image.removeAttribute("style");
		parent.removeAttribute("style");

		return { "measureHeight": measureHeight,
			"measureWidth": measureWidth,
			"topCSS": TopCSS,
			"middleCSS": MiddleCSS,
			"bottomCSS": BottomCSS,
			"heightCSS": HeightCSS,
			"leftOffset": -(measureWidth / 2)
		};
	},

	getFontMetrics: function(fontFamilyCSS, fontSizeCSS, fontWeightCSS) {
		// Measure the font with the given family, size & weight
		var metricsW = this.measureText(fontFamilyCSS, fontSizeCSS, fontWeightCSS, "W");

		// Measure "XX" with the given family, size & weight
		var metricsXX = this.measureText(fontFamilyCSS, fontSizeCSS, fontWeightCSS, "XX");

		// Measure "X X" with the given family, size & weight
		var metricsX_X = this.measureText(fontFamilyCSS, fontSizeCSS, fontWeightCSS, "X X");

		return {
			"topOffset": metricsW.bottomCSS,
			"leftOffset": metricsW.leftOffset,
			"width": metricsW.measureWidth,
			"height": metricsW.measureHeight,
			"heightAboveBaseline": metricsW.topCSS + metricsW.bottomCSS,
			"anchorImgMarginTop": metricsW.topCSS,
			"spaceWidth": metricsX_X.measureWidth - metricsXX.measureWidth
		};
	},

	getWidestChoice: function(itemDef, fontFamilyCSS, fontSizeCSS, fontWeightCSS) {
		// Get the width of the widest choice defined at the initial font size
		var maxChoiceWidth = 0;
		var widestChoiceIdx = undefined;
		var choiceMetrics;
		for (var i = 0; i < itemDef.choices.length; i++) {
			choiceMetrics = app.measureText(fontFamilyCSS, fontSizeCSS, fontWeightCSS, itemDef.choices[i]);
			if (choiceMetrics.measureWidth > maxChoiceWidth) {
				maxChoiceWidth = choiceMetrics.measureWidth;
				widestChoiceIdx = i;
			}
		}

		return {
			"maxChoiceWidth": maxChoiceWidth,
			"widestChoiceIdx": widestChoiceIdx
		};
	},

	getAnswerAreaChoiceMetrics: function(answerArea, choiceSelectMargin, textToMeasure) {
		// Get the answer area's font size so we can measure the text properly (default font is choice font)
		var fontSizeStr = answerArea.css("font-size");
		var fontWeightStr = answerArea.css("font-weight");
		var answerAreaHeightStr = answerArea.css("height");
		var answerAreaHeightNum = Number(answerAreaHeightStr.replace("px", ""));

		// Measure the text as it would appear in the answer area
		var metrics = app.measureText(null, fontSizeStr, fontWeightStr, textToMeasure);

		// Dimensions of choice will be a square that can hold any letter, expanded by a selection margin.
		// Position of choice (top & left offsets) assume an origin on the center of the top edge of the choice
		//   matches a point on the top edge (top solid line) of the answer area.
		var margin = metrics.measureHeight - answerAreaHeightNum + metrics.bottomCSS + choiceSelectMargin;
		var choiceWidth = metrics.measureWidth;
		var choiceHeight = metrics.measureHeight + metrics.bottomCSS + margin;
		var lineHeight = metrics.topCSS + metrics.bottomCSS;

		return {"choiceTopOffset": -margin,
			"choiceLeftOffset": -(choiceWidth / 2),
			"choiceWidth": choiceWidth,
			"choiceHeight": choiceHeight,
			"lineHeight": lineHeight,
			"paddingTop": margin,
			"paddingBottom": 0 };
	},

	getAnswerAreaSpaceWidth: function(answerArea) {
		// Get the answer area's font size so we can measure the text properly (default font is choice font)
		var fontSizeStr = answerArea.css("font-size");
		var fontWeightStr = answerArea.css("font-weight");

		// Measure "XX" as it would appear in the answer area
		var metricsXX = app.measureText(null, fontSizeStr, fontWeightStr, "XX");

		// Measure "X X" as it would appear in the answer area
		var metricsX_X = app.measureText(null, fontSizeStr, fontWeightStr, "X X");

		// Width of space character is difference of measureWidths
		return (metricsX_X.measureWidth - metricsXX.measureWidth);
	}
};
