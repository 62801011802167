var logging = {

	init: function() {
		this._serverURL = window.l2mConfig.serverUrl;
		this._testDeliveryID = 0;

		// Key for global test state in HTML5 Local Storage (no session variables)
		this._SSKey = "L2MTestSS";

		this._responseStrings = [
			// Result codes that must match the "Result_Code" table in the database server
			"OK",
			"Invalid user ID or password -- try again!",
			"Invalid test requested -- please check with your system administrator!",
			"Can't deliver the requested test -- please check with your system administrator!",
			"Database error -- please check with your system administrator!",

			// Other result codes (e.g. standalone)
			"You need to log in to the assessment first!",
			"Couldn't create the test results file!  Make sure the disk isn't full or locked.",
			"Couldn't update the test results file!  Make sure the disk isn't full or locked."
		];

		this._defaultEventCallbackBound = function(cbData) {
			if ((typeof cbData !== 'undefined') &&
                (cbData.responseID == "uploadResult") &&
                (cbData.resultCode != 0)) {
				alert(this._responseStrings[cbData.resultCode]);
			}
		}.bind(this);
	},

	logVerbose: function(message) {
		if (l2mConfig.shouldLogVerbose) {
			console.log(message);
		}
	},

	zeroPadTo2: function(n) {
		return ((n < 10) ? `0${n}` : `${n}`);
	},

	zeroPadTo3: function(n) {
		return ((n < 100) ? `0${this.zeroPadTo2(n)}` : `${n}`);
	},

	logGetTestState: function() {
		var testState = $.parseJSON(sessionStorage.getItem(this._SSKey));

		// Cache test instance ID for logging calls
		if ((typeof testState !== 'undefined') &&
            (testState != null)) {
			this._testDeliveryID = testState.testDeliveryID;
		}
		return testState;
	},

	logSetTestState: function(testState) {
		sessionStorage.setItem(this._SSKey, JSON.stringify(testState));
		this._testDeliveryID = testState.testDeliveryID;
	},

	logClearTestState: function() {
		sessionStorage.removeItem(this._SSKey);
		this._testDeliveryID = 0;
	},

	logRedirectLogin: function() {
		alert(this._responseStrings[5]);
		window.location = "l2m.html";
	},

	logAjaxFailed: function(result) {
		this.logVerbose("Entering logAjaxFailed");
		alert(`Failed: ${result.status} : ${result.statusText}`);
	},

	logSendToServer: function(JSONData, cb) {
		this.logVerbose("Entering logSendToServer");
		this.logVerbose(`  server URL: ${this._serverURL}`);
		this.logVerbose(`  data: ${JSONData}`);

		$.ajax({
			url: this._serverURL,
			async: false,
			data: JSONData,
			dataType: 'json',
			type: 'POST',
			contentType: 'application/json',
			success: cb,
			error: function(msg) {
				throw new Error(`Server communication failed for student ID ${app._childId}: '${msg.status}' : '${msg.statusText}'; JSON payload: '${JSONData}'`);
			}
		});

		this.logVerbose("Leaving logSendToServer");
	},

	printCallback: function(cbData) {
		// Just echo the data to the console
		this.logVerbose(cbData);
	},

	handleLoginResponse: function(cbData, callback) {
		//this.logVerbose("entering handleLoginResponse");
		if (cbData &&
            (cbData.responseID == "loginResult")) {
			// Copy the result code into the payload (soon to be the cached test state)
			cbData.payload.resultCode = cbData.resultCode;

			if (!cbData.payload.loginOK) {
				// Get a human-readable message for any errors
				cbData.payload.resultString = this._responseStrings[cbData.payload.resultCode];
			} else {
				// Promote any test delivery state to session storage
				if (typeof (cbData.payload.testDeliveryState) === "string") {
					var tds = $.parseJSON(cbData.payload.testDeliveryState);
					for (var stateName in tds) {
						sessionStorage.setItem(stateName, JSON.stringify(tds[stateName]));
					}
				}
			}

			// Cache the data into local storage
			this.logSetTestState(cbData.payload);

			// Call the user's callback with the test state
			if (typeof callback === "function") {
				callback(cbData.payload);
			}
		}
	},

	logLoginRequest: function(pageName, testName, userName, password, callback) {
		var ev = {};

		ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
		ev.pageName = ((pageName === null) ? document.URL : pageName);
		ev.eventID = "loginRequest";
		ev.payload = {};

		ev.payload.testName = testName;
		ev.payload.userName = userName;
		ev.payload.password = password;

		var evJSON = JSON.stringify(ev);
		this.logVerbose(evJSON);

		// Make sure there's no stale test state in local storage
		this.logClearTestState();

		if (typeof this._serverURL !== 'undefined') {
			this.logSendToServer(evJSON, function (cbData) {
				this.logVerbose("entering logLoginCallback");
				this.handleLoginResponse(cbData, callback);
			});
		} else {
			// No local/remote server -- check for a
			// non-empty username & mock up a response
			var cbData = {};
			cbData.responseID = "loginResult";
			cbData.payload = {};

			if (userName &&
                (userName != "")) {
				var spaceIdx = userName.indexOf(" ");

				if (spaceIdx > 0) {
					cbData.payload.firstName = userName.substr(0, spaceIdx);
					cbData.payload.lastName = userName.substr(spaceIdx + 1);
				} else {
					cbData.payload.firstName = userName;
					cbData.payload.lastName = "";
				}
				cbData.payload.RFUID = cbData.payload.firstName + cbData.payload.lastName;
				cbData.payload.loginOK = true;
				cbData.payload.testDeliveryID = -1;
				cbData.payload.childID = -1;
				cbData.payload.childGrade = ((password == "k") || (password == "K") || (password == "1") || (password == "2")) ? 2 : 3;
			} else {
				cbData.payload.loginOK = false;
				cbData.payload.resultCode = 1;
			}

			this.handleLoginResponse(cbData, callback);
		}
	},

	getTestDelivery: function(pageName, testName, schoolID, childID, grade, callback) {
		if (typeof this._serverURL !== 'undefined') {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "getTestDelivery";
			ev.payload = {};

			ev.payload.testName = testName;
			ev.payload.schoolID = schoolID;
			ev.payload.childID = childID;
			ev.payload.grade = grade;

			var evJSON = JSON.stringify(ev);
			this.logVerbose(evJSON);

			// Provide a default user callback, if necessary
			this.logVerbose(`typeof callback = ${typeof callback}`);
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logPageLoad: function(pageName, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "pageLoad";

			this.logVerbose(`Timestamp: ${ev.timestamp}`);
			var timestampStr = `${this.zeroPadTo2(ev.timestamp.getMonth() + 1)}/${ 
				this.zeroPadTo2(ev.timestamp.getDate())}/${ 
				ev.timestamp.getFullYear()} ${ 
				this.zeroPadTo2(ev.timestamp.getHours())}:${ 
				this.zeroPadTo2(ev.timestamp.getMinutes())}:${ 
				this.zeroPadTo2(ev.timestamp.getSeconds())}.${ 
				this.zeroPadTo3(ev.timestamp.getUTCMilliseconds())}`;
			this.logVerbose(`Timestamp: ${timestampStr}`);

			var evJSON = JSON.stringify(ev);
			this.logVerbose(`evJSON: ${evJSON}`);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}
			this.logSendToServer(evJSON, callback);
		}
	},

	logButtonPressed: function(pageName, buttonName, buttonEnabled, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "buttonPressed";
			ev.payload = {};

			ev.payload.buttonName = buttonName;
			if (buttonEnabled == undefined) {
				buttonEnabled = true;
			}
			ev.payload.buttonEnabled = buttonEnabled;

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logSoundStarted: function(pageName, soundName, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "soundStarted";
			ev.payload = {};

			ev.payload.soundName = soundName;

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logSoundEnded: function(pageName, soundName, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "soundEnded";
			ev.payload = {};

			ev.payload.soundName = soundName;

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logItemResponse: function(pageName, itemID, itemLabel, responseIdx, response, isCorrect, responsesEnabled, callback) {
		if (
			(typeof this._serverURL !== 'undefined') &&
			(typeof this._testDeliveryID !== 'undefined') &&
			(this._testDeliveryID !== 0)
		) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "itemResponse";
			ev.payload = {};

			ev.payload.itemID = itemID;
			ev.payload.itemLabel = itemLabel;
			ev.payload.responseIdx = responseIdx;
			ev.payload.response = response;
			ev.payload.isCorrect = isCorrect;
			ev.payload.responsesEnabled = responsesEnabled;

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logItemCompleted: function(pageName, itemID, itemLabel, responseIdx, response, isCorrect, abilityEstimateGE, responsesEnabled, totalTime_ms, standardError, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "itemCompleted";
			ev.payload = {};

			ev.payload.itemID = itemID;
			ev.payload.itemLabel = itemLabel;
			ev.payload.responseIdx = responseIdx;
			ev.payload.response = response;
			ev.payload.isCorrect = isCorrect;
			ev.payload.abilityEstimateGE = abilityEstimateGE;
			ev.payload.responsesEnabled = responsesEnabled;
			ev.payload.totalTime_ms = totalTime_ms;
			ev.payload.standardError = standardError;

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logTestStarted: function(pageName, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "testStarted";

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logTestResumed: function(pageName, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "testResumed";

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logTestCompleted: function(pageName, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "testCompleted";

			var evJSON = JSON.stringify(ev);

			// Make sure there's no stale test state in local storage
			this.logClearTestState();

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	},

	logTextEntered: function(pageName, textName, textValue, callback) {
		if ((typeof this._serverURL !== 'undefined') &&
            (typeof this._testDeliveryID !== 'undefined') &&
            (this._testDeliveryID != 0)) {
			var ev = {};

			ev.timestamp = moment().format('YYYY-MM-DD HH:mm:ss');
			ev.testDeliveryID = this._testDeliveryID;
			ev.pageName = ((pageName === null) ? document.URL : pageName);
			ev.eventID = "textEntered";
			ev.payload = {};

			ev.payload.textName = textName;
			ev.payload.textValue = textValue;

			var evJSON = JSON.stringify(ev);

			// Provide a default user callback, if necessary
			if (typeof callback === 'undefined') {
				callback = this._defaultEventCallbackBound;
			}

			this.logSendToServer(evJSON, callback);
		}
	}
};
